<template>
  <v-app class="cms">
    <app-bar @change="setDrawer($event)"/>

    <drawer :drawer="this.drawer"/>

    <app-view />

  </v-app>
</template>

<script>
  export default {
    name: 'Index',
    data(){
      return{
        drawer:true
      }
    },
    methods:{
      setDrawer(el){
        this.drawer = el
      }
    },
    components: {
      AppBar: () => import('../cms/commons/AppBar'),
      Drawer: () => import('../cms/commons/Drawer'),
      AppView: () => import('../cms/commons/View'),
    }
  }
</script>
